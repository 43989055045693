<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="12">
        <ecommerce-statistics
          v-if="$can('view', 'dashboard')"
          :data="data.statisticsItems.filter(i => i.show)"
          :title="currentDateFormatted"
          :disabled-next="disabledNext"
          @previous="previous"
          @next="next"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import {
  addMonths, format, isFuture, subMonths,
} from 'date-fns'
import ptBrLocale from 'date-fns/locale/pt-BR'

import { getUserData } from '@/auth/utils'
import EcommerceStatistics from './EcommerceStatistics.vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
  },
  data() {
    return {
      currentDate: new Date(),
      disabledNext: true,
      currentDateFormatted: null,
      data: {
        statisticsItems: [
          {
            show: true,
            icon: 'UsersIcon',
            color: 'light-info',
            title: '0',
            subtitle: 'Médicos',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            show: true,
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: 'R$0,00',
            subtitle: 'Limite Empréstimo',
            customClass: '',
          },
          {
            show: true,
            icon: 'ClockIcon',
            color: 'light-warning',
            title: 'R$0,00',
            subtitle: 'Valor lançado',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            show: true,
            icon: 'ArrowUpRightIcon',
            color: 'light-danger',
            title: 'R$0,00',
            subtitle: 'Valor antecipado',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            show: false,
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: 'R$0,00',
            subtitle: 'Cashback',
            customClass: 'mb-2 mb-sm-0',
          },
        ],
      },
    }
  },
  created() {
    if (this.$can('view', 'dashboard')) {
      this.refresh(this.currentDate)
    }
  },
  methods: {
    previous() {
      this.currentDate = subMonths(this.currentDate, 1)
      this.refresh(this.currentDate)
      this.disabledNext = false
    },
    next() {
      const currentDate = addMonths(this.currentDate, 1)
      const futureDate = isFuture(currentDate)
      if (!futureDate) {
        this.currentDate = currentDate
        this.refresh(this.currentDate)
      } else {
        this.disabledNext = true
      }
    },
    refresh(date) {
      const numberFormat = value => new Intl.NumberFormat('id').format(value)
      this.currentDateFormatted = format(date, 'MMMM yyyy', { locale: ptBrLocale }).toUpperCase()

      const dateParam = format(date, 'yyyy-MM')
      this.$http.get('/institution-report/getInstitutionPhysician', { params: { date: dateParam } })
        .then(response => {
          if (response.data.data.quantity_physician !== undefined) {
            this.data.statisticsItems[0].title = `${numberFormat(response.data.data.quantity_physician)}`
          }
        })

      const user = getUserData()
      if (user && user.show_cashback) {
        this.$http.get('/institution-report/advanced-cashback', { params: { date: dateParam } })
          .then(response => {
            this.data.statisticsItems[4].show = true
            if (response.data.data.institution_cashback !== undefined) {
              this.data.statisticsItems[4].title = `R$${numberFormat(response.data.data.institution_cashback / 100)}`
            }
          })
      }

      this.$http.get('/institution-report/getInstitutionLimit', { params: { date: dateParam } })
        .then(response => {
          if (response.data.data.limit_institution !== undefined) {
            this.data.statisticsItems[1].title = `R$${numberFormat(response.data.data.limit_institution / 100)}`
          }
        })

      this.$http.get('/institution-report/amount-all-service', { params: { date: dateParam } })
        .then(response => {
          if (response.data.data.amount_scheduled !== undefined) {
            this.data.statisticsItems[2].title = `R$${numberFormat(response.data.data.amount_scheduled / 100)}`
          }
          if (response.data.data.amount_advanced !== undefined) {
            this.data.statisticsItems[3].title = `R$${numberFormat(response.data.data.amount_advanced / 100)}`
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
